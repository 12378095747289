const initialState = {
  coupons: [],
  categories: [],
  products: [],
  draftproducts: [],
};

export const adminReducer = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    // Coupons
    case "LOAD_ALL_COUPON":
      // console.log("STATE:", state);
      state.coupons = action.payload;
      break;
    case "ADD_COUPON":
      // console.log("STATE:", state);
      state.coupons.push(action.payload);
      break;
    case "UPDATE_COUPON":
      // console.log("STATE:", state);
      state.coupons = state.coupons.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      break;
    case "DELETE_COUPON":
      // console.log("STATE:", state);
      state.coupons = state.coupons.filter(
        (item) => item.id !== action.payload
      );
      break;
    // Categories
    case "LOAD_ALL_CATEGORY":
      // console.log("STATE:", state);
      state.categories = action.payload;
      break;
    case "ADD_CATEGORY":
      // console.log("STATE:", state);
      state.categories.push(action.payload);
      break;
    case "UPDATE_CATEGORY":
      // console.log("STATE:", state);
      state.categories = state.categories.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      break;
    case "PARTIAL_UPDATE_CATEGORY":
      // console.log("STATE:", state);
      state.categories = state.categories.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      break;
    case "DELETE_CATEGORY":
      // console.log("STATE:", state);
      state.categories = state.categories.filter(
        (item) => item.id !== action.payload
      );
      break;

    // Products
    case "LOAD_ALL_PRODUCT":
      // console.log("STATE:", state);
      state.products = action.payload;
      break;
    case "ADD_PRODUCT":
      // console.log("STATE:", state);
      state.products.push(action.payload);
      break;
    case "UPDATE_PRODUCT":
      // console.log("STATE:", state);
      state.products = state.products.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      break;
    case "ARCHIVE_PRODUCT":
      state.products = state.products.filter(
        (product) => product.id !== action.payload
      );
      break;

    // Draft Products
    case "LOAD_ALL_DRAFT_PRODUCT":
      // console.log("STATE:", state);
      state.draftproducts = action.payload;
      break;
    case "UPDATE_DRAFT_PRODUCT":
      // console.log("STATE:", state);
      state.draftproducts = state.draftproducts.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      break;
    default:
    // console.log("STATE:", state);
    // console.log("")
  }
  return state;
};
