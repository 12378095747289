import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { combineReducers } from "redux-immer";
import produce from "immer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { userReducer } from "reducers/UserReducer";
import { adminReducer } from "reducers/AdminReducer";
import { authReducer } from "reducers/AuthReducer";
import { businessReducer } from "reducers/BusinessReducer";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'


const persistConfig = {
  key: "authReducer",
  storage: storage,
  whitelist: ["authReducer"],
  stateReconciler: autoMergeLevel2
};

const rootReducer = combineReducers(produce, {
  authReducer: authReducer,
  adminReducer: adminReducer,
  userReducer: userReducer,
  businessReducer: businessReducer,
});

const pReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  pReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const persistor = persistStore(store);

// window.store = store;

export { persistor, store };
