const initialState = {
  marketing: [],
};

export const businessReducer = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case "SEND_MESSAGE":
      // console.log("State", state);
      state.marketing = action.payload;
      break;
    default:
      // console.log("State", state);
      // console.log("")
  }
  return state;
};
