import React, { useEffect, useState, Suspense, lazy } from "react";

import moment from "moment";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { Container, Spinner, Button as BootstrapButton } from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as actions from "actions/AuthActions";
import { useCurrentWidth } from "react-socks";

import { ToastContainer } from "react-toastify";
import { FaChevronUp } from "react-icons/fa";
import { persistor } from "store";

import Loader from "components/Loader/Loader";

const Home = lazy(() => import("pages/Home/Home"));
const Product = lazy(() => import("pages/Product/Product"));
const RedirectUrl = lazy(() => import("pages/Product/RedirectUrl"));
const Viewer = lazy(() => import("pages/Product/Viewer"));
const ImageViewer = lazy(() => import("pages/Product/ImageViewer"));
const SearchResults = lazy(() => import("pages/SearchResults/SearchResults"));
const BlogsIndex = lazy(() => import("pages/Blogs"));
const Blogs = lazy(() => import("pages/Blogs/Blogs"));
const Blog = lazy(() => import("pages/Blogs/Blog"));
const Try3D = lazy(() => import("pages/Try3D/Try3D"));

const AdminProduct = lazy(() => import("pages/admin/Product/Product"));
const AdminProductMain = lazy(() => import("pages/admin/Product/ProductMain"));
const AdminCategories = lazy(() => import("pages/admin/Categories/Categories"));
const AdminCategoriesMain = lazy(() =>
  import("pages/admin/Categories/CategoriesMain")
);
const AdminCategoryProducts = lazy(() =>
  import("pages/admin/Categories/CategoryProducts")
);
const AdminCoupons = lazy(() => import("pages/admin/Coupons/Coupons"));
const AdminLogin = lazy(() => import("pages/admin/Auth/Login"));
const Admin = lazy(() => import("pages/admin/Admin"));
// import Editor from "pages/admin/Product/Editor";
const AdminPayouts = lazy(() => import("pages/admin/Payouts/Payouts"));
const Approvals = lazy(() => import("pages/admin/Approvals/Approvals"));
const AdminProducts = lazy(() => import("pages/admin/Products/Products"));
const AdminBlogsIndex = lazy(() => import("pages/admin/Blogs"));
const AdminBlogs = lazy(() => import("pages/admin/Blogs/Blogs"));
const AdminBlog = lazy(() => import("pages/admin/Blogs/Blog"));
const AdminBlogUpdate = lazy(() => import("pages/admin/Blogs/BlogUpdate"));
const AdminNewBlog = lazy(() => import("pages/admin/Blogs/NewBlog"));
const AdminStatistics = lazy(() => import("pages/admin/Statistics/Statistics"));
const AdminStatisticsMain = lazy(() =>
  import("pages/admin/Statistics/StatisticsMain")
);

const Cart = lazy(() => import("pages/Cart/Cart"));
const OrderDetails = lazy(() => import("pages/Cart/OrderDetails"));
const OrderSuccessful = lazy(() => import("pages/Cart/OrderSuccessful"));

const ProductFiles = lazy(() => import("pages/admin/Product/ProductFiles"));
const Profile = lazy(() => import("pages/Account/Profile"));
const Favourites = lazy(() => import("pages/Account/Favourites"));
const Orders = lazy(() => import("pages/Account/Orders"));

const Categories = lazy(() => import("pages/categories/Categories"));
const CategoryProducts = lazy(() =>
  import("pages/categories/CategoryProducts/CategoryProducts")
);

const Navbar = lazy(() => import("components/Navbar/NavBar"));
const Footer = lazy(() => import("components/Footer/Footer"));
const MobileLogin = lazy(() => import("components/MobileLogin/MobileLogin"));
const SellerCreated = lazy(() =>
  import("components/SellerCreated/SellerCreated")
);

const ProductEditor = lazy(() => import("pages/ProductEditor/ProductEditor"));

const Seller = lazy(() => import("pages/Seller/Seller"));
const MyProducts = lazy(() => import("pages/Seller/MyProducts"));
const SellerLogin = lazy(() => import("pages/Seller/Auth/SellerLogin"));
const SellerLanding = lazy(() => import("pages/Seller/SellerLanding"));

const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy/PrivacyPolicy"));
const RefundAndCancellation = lazy(() =>
  import("pages/RefundAndCancellation/RefundAndCancellation")
);
const SellerTermsOfUse = lazy(() =>
  import("pages/TermsOfUse/SellerTermsOfUse")
);
const UserTermsOfUse = lazy(() => import("pages/TermsOfUse/UserTermsOfUse"));
const CookiesPolicy = lazy(() => import("pages/PrivacyPolicy/CookiesPolicy"));

const AboutUsPolicy = lazy(() => import("pages/AboutUsPolicy/AboutUsPolicy"));
const VerifyAccount = lazy(() => import("pages/Account/VerifyAccount"));
const ResetPassword = lazy(() => import("pages/Account/ResetPassword"));
const ContactUs = lazy(() => import("pages/ContactUs"));
const SiteMap = lazy(() => import("pages/SiteMap/SiteMap"));

const ScrollToTop = lazy(() => import("components/ScrollToTop/ScrollToTop"));

const Business = lazy(() => import("pages/Business/Business"));
const BusinessHome = lazy(() => import("pages/Business/BusinessHome"));
const CaseStudy = lazy(() => import("pages/Business/CaseStudy/CaseStudy"));
const CaseStudy2 = lazy(() => import("pages/Business/CaseStudy/CaseStudy2"));
const CaseStudy3 = lazy(() => import("pages/Business/CaseStudy/CaseStudy3"));

const Error = lazy(() => import("pages/Error/Error"));
const MobileNotification = lazy(() =>
  import("components/Notifications/MobileNotification")
);
const WeAreInNewsPage = lazy(() => import("pages/WeAreInNewsPage"));
const License = lazy(() => import("pages/License/License"));
const VRSolutions = lazy(() => import("pages/VRSolutions/VRSolutions"));
const VRSolutionsPrivacyPolicy = lazy(() =>
  import("pages/VRSolutions/VRSolutionsPrivacyPolicy")
);
const VRTraining = lazy(() => import("pages/VRSolutions/VRTraining"));
const VRTrainingPrivacyPolicy = lazy(() =>
  import("pages/VRSolutions/VRTrainingPrivacyPolicy")
);



const Routers = () => {
  const token = useSelector((state) => state.authReducer.token);
  const user = useSelector((state) => state.authReducer.user);
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  // const loading = useSelector(
  //   (state) => state.authReducer.loading
  // );
  const isAdmin = useSelector((state) => state?.authReducer?.user?.is_admin);
  const isSuperuser = useSelector(
    (state) => state?.authReducer?.user?.is_superuser
  );
  const sessionExpiry = useSelector(
    (state) => state?.authReducer?.session_expiry
  );

  const cartProducts = useSelector(
    (state) => state?.authReducer?.user?.cartproducts
  );
  const deviceWidth = useCurrentWidth();

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  const [executed, setExecuted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loginShow, setLoginShow] = useState(false);

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 1000) {
      setVisible(true);
    } else if (scrolled <= 1000) {
      setVisible(false);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    // console.log("isAuthenticated", isAuthenticated, user);
    if (!user) {
      persistor.purge();
      // console.log("PURGED");
    }
  }, [user]);

  const handleActivity = async (url, user_id) => {
    const urlList = url.split("/");
    if (!urlList.includes("search")) {
      const data = {
        user: user_id,
        url_visited: url,
      };
      try {
        const response = await axios.post("/user/save_activity/", data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        // console.log("__Response", response);
      } catch (error) {
        // console.error("__Error:", error);
      }
    }
  };

  // useEffect(() => {
  //   if (isAuthenticated !== undefined) {
  //     if (!isAuthenticated) {
  //       handleActivity(window.location.href, null);
  //     } else {
  //       handleActivity(window.location.href, user.id);
  //     }
  //   }
  // }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      if (
        user.name == "" ||
        user.name == null ||
        user.email == "" ||
        user.email == null
      ) {
        setLoginShow(true);
      }
      if (sessionExpiry) {
        const today = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        const session_expiry = moment(sessionExpiry).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        console.log(today, session_expiry);
        if (today > session_expiry) {
          dispatch(actions.loggingOut());
        }
      } else {
        dispatch(actions.loggingOut());
      }
    }
  }, [isAuthenticated]);

  // axios.defaults.baseURL = "http://192.168.0.120:82/v1"; // Tejas - office
  // axios.defaults.baseURL = "http://192.168.0.124:3000/v1"; // HP - office
  // axios.defaults.baseURL = "http://192.168.221.64:3000/v1"; // Hanuman - office
  // axios.defaults.baseURL = "http://192.168.1.8:3000/v1"; // Home
  // axios.defaults.baseURL = "http://api.znanyetest.teson.co.in/v1"; // Tejas
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (user && token) {
      if (!executed) {
        dispatch(actions.getMyProfile(cartProducts));
        setExecuted(true);
      }
    }
  }, [user, token]);

  window.addEventListener("scroll", toggleVisible);

  if (loading) {
    return (
      <Container
        fluid
        className="d-flex justify-content-center align-items-center position-fixed w-100 h-100 home-background"
      >
        <Spinner animation="border" style={{ color: "#555" }}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Router>
      <Suspense fallback={<Loader/>}>
        <ScrollToTop />
        <Container fluid className="px-0 poppins">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {/* <Offline
          polling={{
            enabled: true,
            url: "https://api.znanye.com/",
          }}
        >
          <Container fluid className="home-background-categories h-100">
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <Image src={offline} alt="Offline" style={{ width: 200 }} />
              <h4 className="my-3 text-white text-center poppins">
                You are offline!
                <br />
                Please check your internet connection!
              </h4>
            </div>
          </Container>
        </Offline> */}

          <Navbar
            loginShow={loginShow}
            setLoginShow={setLoginShow}
            // categoryDropdown={categoryDropdown}
            // setCategoryDropdown={setCategoryDropdown}
            // categoriesRef={categoriesRef}
          />
          {/* <Online
          polling={{
            enabled: true,
            url: "https://api.znanye.com/",
          }}
        > */}
          {/* <IconButton */}
          {/* text={false} */}
          {/* dark={true} */}
          {/* color={"#fff"} */}
          {/* size="large" */}
          {/* className={`position-fixed ${visible ? "d-flex" : "d-none"} `} */}
          {/* style={{ */}
          {/* bottom: deviceWidth > 768 ? 100 : 50, */}
          {/* right: 50, */}
          {/* zIndex: 200, */}
          {/* }} */}
          {/* onClick={() => { */}
          {/* window.scrollTo({ */}
          {/* top: 0, */}
          {/* behavior: "smooth", */}
          {/* you can also use 'auto' behaviour
                   in place of 'smooth' */}
          {/* }); */}
          {/* }} */}
          {/* > */}
          {/* <FaChevronUp /> */}
          {/* </IconButton> */}

          <BootstrapButton
            variant="dark"
            size="lg"
            className={`position-fixed ${visible ? "d-flex" : "d-none"}`}
            style={{
              bottom: deviceWidth > 768 ? 100 : 65,
              right: deviceWidth > 768 ? 50 : 10,
              zIndex: 200,
              color: "#fff",
              backgroundColor: "#1B1C1D",
              borderRadius: "5px",
              border: "1px solid #3D3D3D",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              width: "45px",
              height: "42px",
            }}
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <FaChevronUp style={{ color: "#fff" }} />
          </BootstrapButton>

          <Routes>
            {/* <Route path="" element={<Home categoriesRef={categoriesRef} />} /> */}
            <Route path="try3d" element={<Try3D />} />
            <Route path="login" element={<MobileLogin />} />
            <Route path="sitemap" element={<SiteMap />} />
            <Route path="verify/:email_hash" element={<VerifyAccount />} />
            <Route
              path="reset_password/:email_hash"
              element={<ResetPassword />}
            />
            <Route path="privacy_policy" element={<PrivacyPolicy />} />
            {/* <Route path="terms_and_conditions" element={<Terms />} /> */}
            <Route path="refund_policy" element={<RefundAndCancellation />} />
            {/* <Route path="privacy_policy_user" element={<UserPrivacyPolicy />} />
          <Route path="privacy_policy_seller" element={<SellerPrivacyPolicy />} /> */}
            <Route path="cookies_policy" element={<CookiesPolicy />} />
            <Route path="terms_of_use_user" element={<UserTermsOfUse />} />
            <Route path="terms_of_use_seller" element={<SellerTermsOfUse />} />
            <Route path="about-us-policy" element={<AboutUsPolicy />} />
            <Route path="business" element={<Business />}>
              <Route path="" element={<BusinessHome />} />
            </Route>
            <Route path="casestudy/znanyeland" element={<CaseStudy />} />
            <Route
              path="casestudy/ar-solution-for-tyre-company"
              element={<CaseStudy2 />}
            />
            <Route path="casestudy/dark-den" element={<CaseStudy3 />} />
            <Route path="" element={<Home />} />
            <Route path="contact-us" element={<ContactUs />} />
            {/* <Route path="product" element={<SearchResults />}>
            <Route
              path=":productId"
              element={<Product setLoginShow={setLoginShow} />}
            />
            <Route path=":productId/viewer" element={<Viewer />} />
            <Route path=":productId/imageviewer" element={<ImageViewer />} />
          </Route> */}
            <Route path="categories" element={<RedirectUrl />}>
              <Route path="product" element={<RedirectUrl />} />
            </Route>
            <Route path="product" element={<RedirectUrl />}>
              <Route path=":productId" element={<RedirectUrl />} />
            </Route>
            <Route path="search" element={<SearchResults />}>
              <Route path=":productId" element={<RedirectUrl />} />
              <Route path=":productId/viewer" element={<RedirectUrl />} />
              {/* <Route path=":productId/imageviewer" element={<ImageViewer />} /> */}
            </Route>
            <Route path="cart" element={<Cart />}>
              <Route
                path=""
                element={<OrderDetails setLoginShow={setLoginShow} />}
              />
              {/* <Route path="payment" element={<PaymentDetails />} /> */}
              <Route path="thankyou" element={<OrderSuccessful />} />
            </Route>
            <Route path="3dmodels" element={<Categories />}>
              <Route path=":categorySlug" element={<CategoryProducts />}>
                <Route
                  path=":productId"
                  element={<Product setLoginShow={setLoginShow} />}
                />
                <Route path=":productId/viewer" element={<Viewer />} />
                <Route
                  path=":productId/imageviewer"
                  element={<ImageViewer />}
                />
              </Route>
            </Route>
            {/* <Route path="search" element={<SearchResults />} /> */}
            {/* <Route path="" element={<AdminProduct />} /> */}

            {isAuthenticated && isAdmin && (
              <Route path="admin" element={<Admin />}>
                {/* <Route path="" element={<AdminProduct mode="admin" />} /> */}
                <Route path="" element={<ProductFiles mode="admin" />} />
                <Route path="statistics" element={<AdminStatistics />}>
                  <Route path="" element={<AdminStatisticsMain />} />
                </Route>
                <Route path="product" element={<AdminProduct />}>
                  <Route
                    path=":productId"
                    element={<AdminProductMain type="update" mode="admin" />}
                  />
                  {/* <Route
                  path=":productId"
                  element={<AdminProductMain type="upload" mode="admin" />}
                /> */}
                </Route>
                <Route path="products" element={<AdminProducts />} />
                <Route path="categories" element={<AdminCategories />}>
                  <Route path="" element={<AdminCategoriesMain />} />
                  <Route
                    path=":categoryId"
                    element={<AdminCategoryProducts />}
                  />
                </Route>
                <Route path="approvals" element={<Approvals />} />
                <Route path="coupons" element={<AdminCoupons />} />
                <Route path="blogs" element={<AdminBlogsIndex />}>
                  <Route index element={<AdminBlogs />} />
                  <Route path="new" element={<AdminNewBlog />} />
                  <Route path=":id" element={<AdminBlog />} />
                  <Route path=":id/edit/" element={<AdminBlogUpdate />} />
                </Route>
              </Route>
            )}

            {isAuthenticated && isSuperuser && (
              <Route path="admin" element={<Admin />}>
                <Route path="payouts" element={<AdminPayouts />} />
              </Route>
            )}

            <Route path="admin" element={<AdminLogin />} />
            <Route path="profile" element={<Profile />} />
            <Route path="favourites" element={<Favourites />} />
            <Route path="orders" element={<Orders />} />

            {isAuthenticated && (
              <>
                <Route path="sell-3d" element={<Seller type="empty" />}>
                  <Route path="uploadproducts" element={<ProductFiles />} />
                  {/* <Route path=":productId" element */}

                  <Route
                    path="uploadproducts/producteditor"
                    element={<ProductEditor />}
                  />
                  <Route
                    path="uploadproducts/product"
                    element={<AdminProduct mode="seller" />}
                  />

                  <Route path="product" element={<AdminProduct />}>
                    <Route
                      path=":productId"
                      element={<AdminProductMain type="update" mode="seller" />}
                    />
                    {/* <Route
                    path=":productId"
                    element={<AdminProductMain type="upload" mode="seller" />}
                  /> */}
                  </Route>
                  <Route path="drafts" element={<MyProducts type="drafts" />} />
                  <Route
                    path="myproducts"
                    element={<MyProducts type="approved_products" />}
                  />
                  <Route
                    path="pendingapprovals"
                    element={<MyProducts type="pending_approvals" />}
                  />
                  <Route path="categories" element={<RedirectUrl />}>
                    <Route path=":categoryId" element={<RedirectUrl />} />
                  </Route>
                  <Route path="coupons" element={<RedirectUrl />} />
                </Route>
                <Route path="notifications" element={<MobileNotification />} />
              </>
            )}

            <Route path="vrtraining" element={<VRTraining />}>
              <Route
                path="privacy_policy"
                element={<VRTrainingPrivacyPolicy />}
              />
            </Route>
            <Route path="vrsolutions" element={<VRSolutions />}>
              <Route
                path="privacy_policy"
                element={<VRSolutionsPrivacyPolicy />}
              />
            </Route>
            <Route path="sell-3d-login" element={<SellerLogin />} />
            <Route path="sell-3d" element={<SellerLanding />} />
            <Route
              path="verify_seller/:email_hash"
              element={<VerifyAccount />}
            />
            <Route
              path="reset_seller_password/:email_hash"
              element={<ResetPassword />}
            />
            <Route path="sellercreated" element={<SellerCreated />} />
            <Route path="blogs" element={<BlogsIndex />}>
              <Route index element={<Blogs />} />
              <Route path=":id" element={<Blog />} />
            </Route>
            {/* <Route path="blogs" element={<Blogs />}>
            <Route path=":typeId" element={<BlogList />}>
              <Route path=":id" element={<Blog />} />
            </Route>
          </Route> */}
            <Route path="we-are-in-news" element={<WeAreInNewsPage />} />
            <Route path="license" element={<License />}></Route>
            <Route path="*" element={<Error />}></Route>
          </Routes>
          <Footer />
          {/* </Online> */}
        </Container>
      </Suspense>
    </Router>
  );
};

export default Routers;
